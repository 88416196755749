<template>
	<div class="title">
		<div class="title_name">
			<div class="title_name_txt">
        智慧环卫演示版gs
			</div>
			<div class="title_name_img">
				<div class="title_name_gd"></div>
			</div>
		</div>
		<div class="title_detail">
			<!-- <div class="title_day">
				
			</div> -->
			<NewAniBorder :width="140" :height="40">
				<div class="title_day">
					{{date}}
				</div>
			</NewAniBorder>
			<NewAniBorder :width="85" :height="40" style="margin: 0 10px;">
				<div class="title_week">
					{{week}}
				</div>
			</NewAniBorder>
			<!-- <div class="title_week">
				{{week}}
			</div> -->
			<NewAniBorder :width="140" :height="40" v-if="weather.length>0">
				<div class="title_weather">
					{{weather}}
				</div>
			</NewAniBorder>
			<!-- <div class="title_weather" v-if="weather.length>0">
				{{weather}}
			</div> -->
		</div>
		<!-- <NewCanvas></NewCanvas> -->
		<div class="title-left">
			<div class="title-left-box"></div>
		</div>
		<div class="title-right">
			<div class="title-right-box"></div>
		</div>
	</div>
</template>

<script>
	import {
		mapActions,
		mapGetters
	} from 'vuex';
	// import axios from 'axios';
	import {
		formatDate
	} from '@/utils/dateRangUtil';
	import NewAniBorder from './NewAniBorder.vue'
	export default {
		components: {
			NewAniBorder
		},
		data() {
			return {
				// title
				date: '',
				week: '',
				weather: '',
				titleListVisible: false,
				city: '潍坊',

			};
		},
		computed: {
			...mapGetters({
				'indexName': 'getIndexName',
			})
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				let date = new Date()
				this.date = formatDate(date)
				let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
				this.week = weeks[date.getDay()]
				this.getWeather()
			},
			// 获取当天天气
			getWeather() {
				let params = {
					city: this.city
				}
				this.$http.getWeather(params).then(res => {
					if (res.code === 200&&res.result) {
						let data = JSON.parse(res.result.replace(/\\/g, ''))
						let todayWeather = data.data.forecast[0]
						this.weather = todayWeather.low.substring(3, 6) + ' ~ ' + todayWeather.high.substring(3, 6)
					}
				})
			},
		},
	}
</script>

<style lang='scss' scoped>
	.title {
		width: 100%;
		height: 115px;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 400;
		text-align: center;

		.title_name {
			width: 100%;
			height: 84px;
			line-height: 1;
			font-size: 35px;
			color: #0072FF;
			background: url(../../../../assets/new/map_top.png) no-repeat;
			background-size: 100% 100%;
			padding-top: 20px;
		}

		.title_detail {
			/* width: ; */
			font-size: 16px;
			color: #FFFFFF;
			line-height: 65px;
			font-weight: bold;
			text-align: center;
			position: absolute;
			display: flex;
			align-items: center;
			left: 0;
			top: 30px;
			margin-left: 10px;
			padding-top: 10px;

			.title_day {
				/* width: 142px; */
				height: 40px;
				line-height: 40px;
			}

			.title_week {
				height: 40px;
				line-height: 40px;
			}

			.title_weather {
				height: 40px;
				line-height: 40px;
			}
		}
	}

	.title_name_txt {
		width: 80%;
		background: #00ADFF -webkit-linear-gradient(left, #00ADFF 0%, #aaffff 50%, #00ADFF 100%) no-repeat 0 0;
		background-size: 20% 100%;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-size: 36px;
		text-align: center;
		font-weight: bold;
		text-decoration: underline;
		margin: 0 auto;
	}

	.title_name_txt {
		animation: slideShine 5s linear infinite;
	}
	.title_name_img{
		width: 30%;
		height: 84px;
		position: absolute;
		top: 0;
		left: 50%;
		margin-left: -15%;
		overflow: hidden;
		.title_name_gd{
				width: 352px;
				height: 60px;
				background: url(../../../../assets/new/title_top_line.png) 0 0 no-repeat; 
				background-size: 100% 100%;
				position: absolute;
				top: 43px;
				left: -90%;
				animation: search 5s linear infinite;
			}
	}
	
	@-webkit-keyframes search {
		0% {
			left: -90%;
		}
	
		100% {
			left: 130%;
		}
	}
	
	@-webkit-keyframes slideShine {
		0% {
			background-position: 0 0;
		}

		100% {
			background-position: 100% 100%;
		}
	}

	@keyframes slideShine {
		0% {
			background-position: 0 0;
		}

		100% {
			background-position: 100% 100%;
		}
	}
	.title-left{
		width: 29%;
		height: 60px;
		position: absolute;
		top: 0;
		left: 0;
		overflow: hidden;
	}
	.title-left-box{
		width: 112px;
		height: 5px;
		background: linear-gradient(90deg, #00D2FF 0%, #2968E8 100%);
		border-radius: 2px;
		position: absolute;
		top: 20px;
		right: -112px;
		animation: titleLeft 4s linear infinite;
	}
	.title-right{
		width: 29%;
		height: 60px;
		position: absolute;
		top: 0;
		right: 0;
		overflow: hidden;
	}
	.title-right-box{
		width: 112px;
		height: 5px;
		background: linear-gradient(90deg, #2968E8 0%, #00D2FF 100%);
		border-radius: 2px;
		position: absolute;
		top: 20px;
		left: -112px;
		animation: titleRight 4s linear infinite;
	}
	@keyframes titleLeft {
		0% {
			right: -112px;
		}
	
		100% {
			right: 668px;
		}
	}
	@keyframes titleRight {
		0% {
			left: -112px;
		}
	
		100% {
			left: 668px;
		}
	}
</style>
