<template>
	<div class="ani-cont" :style="{'width':width+'px','height':height+'px','border-radius':height/2+'px'}">
		<div class="ani-cont-color" :style="{'width':width+'px','height':width+'px','top':aniTop+'px'}"></div>
		<div class="ani-cont-post" :style="{'width':aniWidth+'px','height':aniHeight+'px','border-radius':aniRadius+'px'}"></div>
		<div class="ani-cont-detail" :style="{'width':aniWidth+'px','height':aniHeight+'px','border-radius':aniRadius+'px'}">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return {
				
			}
		},
		props:{
			width:{
				type:Number,
				default:0
			},
			height:{
				type:Number,
				default:0
			}
		},
		computed: {
			aniTop() {
				return -((this.width-this.height)/2)
			},
			aniWidth(){
				return this.width-2
			},
			aniHeight(){
				return this.height-2
			},
			aniRadius(){
				return (this.height-2)/2
			}
		},
		mouted(){
			
		},
		methods:{
			
		}
	}
</script>

<style lang="scss" scoped>
	.ani-cont{
		background-color: #0d9dff;
		box-shadow: 0px 0px 17px 1px rgba(51, 102, 255, 0.5);
		position: relative;
		overflow: hidden;
		// margin-top: 40px;
		.ani-cont-color{
			background-color: #0d9dff;
			background-image: conic-gradient(from 0turn at 50% 50%, #0d9dff ,#0d9dff ,#0d9dff ,#0d9dff ,#0d9dff,#0d9dff,#0d9dff,#7dffff,#fff,#7dffff,#0d9dff,#0d9dff,#0d9dff,#0d9dff,#0d9dff,#0d9dff,#0d9dff,#0d9dff,#0d9dff,#0d9dff,#0d9dff ,#0d9dff,#0d9dff,#7dffff,#fff,#7dffff,#0d9dff,#0d9dff,#0d9dff,#0d9dff,#0d9dff,#0d9dff,#0d9dff );
			box-sizing: border-box;
			animation:myfirst 3s linear infinite;
			position: absolute;
			left: 0;
		}
		.ani-cont-post{
			position: absolute;
			left: 1px;
			top: 1px;
			z-index: 9;
			background-color: #eceecb;
		}
		.ani-cont-detail{
			position: absolute;
			left: 1px;
			top: 1px;
			z-index: 9;
			// background-color: rgba(0,0,0,.2);
			background: url(../../../../assets/new/new_bgc.png) 0 0 no-repeat;
			background-size: cover;
		}
	}
	@keyframes myfirst {
	    0% {
	        transform:rotate(0deg);
	    }
	
	    100% {
	        transform:rotate(360deg);
	    }
	}
</style>
