<template>
	<div class="left">
		<div class="left-msg">
			<div class="msg-cont" :class="{'msg-pop':oldType=='person'}">
				<div class="msg-cont-top">
					<p>{{name}}</p>
					<span v-if="oldType == 'car'">{{carType}}</span>
				</div>
				<div class="msg-cont-echar" v-if="oldType == 'car' && refresh">
					<div>
						<Echarts width="147px" height="125px" key="oil" :options="oilOptions"></Echarts>
					</div>
					<div>
						<Echarts width="147px" height="125px" key="speed" :options="speedOptions"></Echarts>
					</div>
				</div>
				<div class="msg-cont-time">时间：{{pointtimestamp}}</div>
				<div class="msg-cont-site">地址：{{pointlatlon}}</div>
				<div class="msg-cont-search">
					<div class="msg-cont-search-line"></div>
					<Tabs :animated=false>
						<TabPane label="按时间查询">
							<div class="tabs-cont-time">
								<ul class="tabs-cont-time-title">
									<li id="today" :class="currentDay==0?'current':''" @click="searchTrack(0)">今天</li>
									<li id="yestoday" :class="currentDay==1?'current':''" @click="searchTrack(1)">昨日
									</li>
									<li id="threeday" :class="currentDay==2?'current':''" @click="searchTrack(2)">近三天
									</li>
								</ul>
								<DatePicker :value="dateInterval" :editable="false" @on-change="dpChageDate"
									type="datetimerange" placeholder="" :options="dateOptions" style="width: 278px"
									class="time-picker"></DatePicker>
							</div>
						</TabPane>
						<TabPane label="按班次查询">
							<div class="tabs-cont-classes">
								<DatePicker type="date" placeholder="" style="width: 278px"></DatePicker>
								<Select v-model="classesValue" style="width:278px">
									<Option v-for="item in classesList" :value="item.value" :key="item.value">
										{{ item.label }}</Option>
								</Select>
							</div>
						</TabPane>
					</Tabs>
					<div class="msg-cont-search-btns" :disabled="disabled" @click="getTracks()">
						<div class="msg-cont-search-btns-item">查询轨迹</div>
					</div>
					<div class="msg-cont-search-line" v-if="oldType!='person'"></div>
				</div>
				<div class="msg-cont-total" v-if="oldType == 'car'">
					<p>总里程:<span>{{mileage.toFixed(2)}}km</span></p>
					<p>油耗:<span>{{oilMassTotal.toFixed(2)}}L</span></p>
				</div>
				<div class="mileage-box" v-if="oldType == 'car'">
					<div class="mileage-box-text">
						<p>百公里油耗:</p>
						<span>{{oilMassHundred == '- -' ? oilMassHundred : oilMassHundred.toFixed(2) + 'L'}}</span>
					</div>
				</div>
				<div v-if="carTypes != '3'">
					<div class="mileage-box" v-if="oldType == 'car'">
						<div class="mileage-box-text">
							<p>作业里程:</p>
							<span>{{homeworkMileage.toFixed(2)}}km</span>
						</div>
					</div>
					<div class="mileage-box" v-if="oldType == 'car'">
						<div class="mileage-box-text">
							<p>空驶里程:</p>
							<span>{{emptyMileage.toFixed(2)}}km</span>
						</div>
						<!-- <Progress :percent="100"
                    hide-info
                    :stroke-color="['#339BC1','#318EC1']" /> -->
					</div>
				</div>
			</div>
		</div>
		<div class="left-bottom" :class="{'left-num-two':collectTwo,'left-num-four':collectFour,'left-num-six':collectSix,'left-num-zero':collectZero}" v-if="carTypes == '3'">
			<div>
				<div class="bin-box-big flex a-center j-between">
					收集点
					<Icon custom="i-icon icon-jiantou2" size="14" color="#fff" class="cursor-pointer no-clean"
						@click.stop="noCleanVisible = !noCleanVisible" :class="{'no-clean-show' : noCleanVisible}" />
				</div>
				<div class="bin-box" v-show="noCleanVisible">
					<p @click="onclickBinTabs(1)" :class='classBinDone'>已清点位：<span>{{binDoneNum}}</span></p>
					<p @click="onclickBinTabs(2)" :class='classBinPending'>未清点位：<span>{{binPendingNum}}</span></p>
					<!-- <p v-for="(item,index) in binTabs"
             :key="index"
             @click="onclickBinTabs(index)">{{item.name}}<span>（{{item.num}}）</span></p> -->
				</div>
			</div>
			<div v-if="((binTabsIndex == 1 && binDoneList.length != 0) || (binTabsIndex == 2 && binPendingList.length != 0))"
				class="bin-list-big" v-show="noCleanVisible">
				<div v-if="binTabsIndex ==1" class="bin-list">
					<div v-for="(item,index) in binDoneList" :key="index" @click="onClickBin(item)">
						<Icon custom="i-icon icon-leixing_lajitong" size="18" color="rgba(0,186,255,.9)" />
						<span>{{item.faclname}}</span>
					</div>
				</div>
				<div v-else class="bin-list">
					<div v-for="(item,index) in binPendingList" :key="index" @click="onClickBin(item)">
						<Icon custom="i-icon icon-leixing_lajitong" size="18" color="#faad14" />
						<span style="color:#faad14">{{item.faclname}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters,
		mapActions
	} from 'vuex'
	import MyCollapse from "../../../common/MyCollapse";
	import MyTreeSelect from '@/components/common/MyTreeSelect';
	import Echarts from '../../../common/Echarts';
	import bus from '@/utils/bus'

	export default {
		components: {
			MyCollapse,
			MyTreeSelect,
			Echarts
		},
		props: {
			oldType: String,
			name: String,
			oilMass: Number,
			carId: String,
			oilMassTotal: Number,
			homeworkMileage: Number,
			oilMassHundred: {
				type: Number | String
			},
			emptyMileage: Number,
			mileage: Number,
			speed: Number,
			carType: String,
			dateInterval: Array,
			pointtimestamp: String,
			pointlatlon: String,
		},
		watch: {
			oilMass(v1, v2) {
				this.oilOptions.series[0].data[0].value = this.oilMass;
				this.refresh = false;
				this.$nextTick(() => {
					this.refresh = true;
				})
			},
			speed(v1, v2) {
				this.speedOptions.series[0].data[0].value = this.speed;
				this.refresh = false;
				this.$nextTick(() => {
					this.refresh = true;
				})
			},
			watch: {},
		},
		computed: {
			...mapGetters({
				// 'departmentTreeArr': 'getDepartmentTreeList',
			}),
			binTabs() {
				let arr = [{
						name: '已清',
						num: this.binDoneNum
					},
					{
						name: '未清',
						num: this.binPendingNum
					}
				]
				return arr
			},
			collectZero(){
				let type = false
				if(this.binDoneNum==0&&this.noCleanVisible||this.binPendingNum==0&&this.noCleanVisible){
					type = true
				}else{
					type = false
				}
				return type
			},
			collectTwo(){
				let type = false
				if(this.binTabsIndex==1&&this.binDoneNum<3&&this.binDoneNum>0&&this.noCleanVisible||this.binTabsIndex==2&&this.binPendingNum<3&&this.binPendingNum>0&&this.noCleanVisible){
					type = true
				}else{
					type = false
				}
				return type
			},
			collectFour(){
				let type = false
				if(this.binTabsIndex==1&&this.binDoneNum<5&&this.binDoneNum>2&&this.noCleanVisible||this.binTabsIndex==2&&this.binPendingNum<5&&this.binPendingNum>2&&this.noCleanVisible){
					type = true
				}else{
					type = false
				}
				return type
			},
			collectSix(){
				let type = false
				if(this.binTabsIndex==1&&this.binDoneNum>4&&this.noCleanVisible||this.binTabsIndex==2&&this.binPendingNum>4&&this.noCleanVisible){
					type = true
				}else{
					type = false
				}
				return type
			}
		},
		data() {
			return {
				dateOptions: {
					disabledDate(date) {
						return date && date.valueOf() > Date.now()
					}
				},
				refresh: true,
				disable: true,
				speedData: 10,
				currentDay: 0,
				classesValue: '',
				classesList: [],
				carPicture: require('../../../../assets/main/carImg.png'),
				personPhoto: require('../../../../assets/main/personImg1.png'),
				oilOptions: {
					series: [{
						type: 'gauge',
						startAngle: 180,
						endAngle: 0,
						min: 0,
						max: 240,
						splitNumber: 12,
						// 指针样式
						itemStyle: {
							color: '#17CE66',
							// shadowColor: 'rgba(0,138,255,0.45)',
							// shadowBlur: 10,
							// shadowOffsetX: 2,
							// shadowOffsetY: 2
						},
						animationThreshold: false, // 开启/关闭动画
						// 展示当前进度
						progress: {
							show: true,
							roundCap: true,
							width: 18
						},
						// 仪表盘指针
						pointer: {
							width: 2,
							offsetCenter: [0, '5%']
						},
						// 轴线相关
						axisLine: {
							roundCap: true,
							lineStyle: {
								width: 6,
								color: [
									[1, '#1AD270']
								]
							}
						},
						// 刻度样式
						axisTick: {
							show: false,
							splitNumber: 2,
							lineStyle: {
								width: 2,
								color: '#999'
							}
						},
						// 分割线样式
						splitLine: {
							show: false,
							length: 12,
							lineStyle: {
								width: 3,
								color: '#999'
							}
						},
						// 刻度标签
						axisLabel: {
							show: false,
							distance: 30,
							color: '#999',
							fontSize: 10
						},
						title: {
							show: true,
							offsetCenter: [0, '50%'],
							fontSize: 10,
							color: '#8F9896',
						},
						detail: {
							valueAnimation: true,
							fontSize: 20,
							color: '#fff',
							offsetCenter: [0, '90%']
						},
						data: [{
							value: 0,
							name: '油量(L)'
						}]
					}]
				},
				speedOptions: {
					series: [{
						type: 'gauge',
						startAngle: 180,
						endAngle: 0,
						min: 0,
						max: 240,
						splitNumber: 12,
						// 指针样式
						itemStyle: {
							color: '#FFA10C',
						},
						animationThreshold: false, // 开启/关闭动画
						// 展示当前进度
						progress: {
							show: true,
							roundCap: true,
							width: 18
						},
						// 仪表盘指针
						pointer: {
							width: 2,
							offsetCenter: [0, '5%']
						},
						// 轴线相关
						axisLine: {
							roundCap: true,
							lineStyle: {
								width: 6,
								color: [
									[1, '#FFA10C']
								]
							}
						},
						// 刻度样式
						axisTick: {
							show: false,
							splitNumber: 2,
							lineStyle: {
								width: 2,
								color: '#999'
							}
						},
						// 分割线样式
						splitLine: {
							show: false,
							length: 12,
							lineStyle: {
								width: 3,
								color: '#999'
							}
						},
						// 刻度标签
						axisLabel: {
							show: false,
							distance: 30,
							color: '#999',
							fontSize: 10
						},
						title: {
							show: true,
							offsetCenter: [0, '50%'],
							fontSize: 10,
							color: '#8F9896',
						},
						detail: {
							valueAnimation: true,
							fontSize: 20,
							color: '#fff',
							offsetCenter: [0, '90%']
						},
						data: [{
							value: 0,
							name: '速度(km/h)'
						}]
					}]
				},
				disabled: 'true',
				carTypes: '',
				binDoneNum: 0,
				binPendingNum: 0,
				classBinDone: 'bin-bit-done',
				classBinPending: '',
				binDoneList: [],
				binPendingList: [],
				binTabsIndex: 1,
				noCleanVisible: false
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			...mapActions([
				'updateDepartmentTreeList',
				'updateCarTypeAllList',
				'updateWorkTypeAllList'
			]),
			searchTrack(pos) {
				this.currentDay = pos;
				this.$emit("setDateInterval", pos);
			},
			init() {
				this.updateDepartmentTreeList()
				this.updateCarTypeAllList()
				this.updateWorkTypeAllList()
				bus.$on('onSearchMenuType', data => {
					this.searchMenuDetailArr = []
					this.searchVisible = true
					this.searchMenuDetailVisible = true
					this.searchMenuDetailTitle = data.title
					this.searchMenuDetailTypeArr = [{
						id: 0,
						name: '全部'
					}]
				})
				this.getCarWorkType()
				bus.$on('resetBinList', () => {
					this.binDoneNum = 0
					this.binDoneList = []
					this.binPendingNum = 0
					this.binPendingList = []
				})
				bus.$on('binList', obj => {
					// this.binDoneNum = this.binDoneNum + obj.cleanListVoList.length
					// this.binDoneList = this.binDoneList.concat(obj.cleanListVoList)
					// this.binPendingNum = this.binPendingNum + obj.notCleanListVoList.length
					// this.binPendingList = this.binPendingList.concat(obj.notCleanListVoList)
					this.binDoneNum = obj.cleanListVoList.length
					this.binDoneList = obj.cleanListVoList
					this.binPendingNum = obj.notCleanListVoList.length
					this.binPendingList = obj.notCleanListVoList
				})
			},
			// 获取车辆作业类型
			getCarWorkType() {
				bus.$on('carJobType', carType => {
					this.carTypes = carType
				})
			},
			getTracks() {
				// if (this.disable) {
				bus.$emit('onSwitch', 'true')
				this.$emit("getInquireTrack");
				// } else {
				//   this.$Message.info('最多选择3天')
				// }
			},
			dpChageDate(d) {
				let dTime = d[1].slice(10)
				if (dTime == ' 00:00:00') {
					let d1 = d[1].slice(0, 11)
					d[1] = d1 + ' 23:59:59'
				}
				let time1 = new Date(d[0]).getTime()
				let time2 = new Date(d[1]).getTime()
				let time3 = time2 - time1
				// if (time3 > 259199000) {
				//   this.disable = false
				// } else {
				this.disable = true
				this.$emit("setDateInterval2", d);
				// }
			},
			// 收集点tab栏切换
			onclickBinTabs(index) {
				if (index == 1) {
					this.classBinDone = 'bin-bit-done'
					this.classBinPending = ''
					this.binTabsIndex = 1
				} else {
					this.classBinDone = ''
					this.classBinPending = 'bin-bit-pending'
					this.binTabsIndex = 2
				}
			},
			// 点击收集点
			onClickBin(item) {
				this.$emit('onClickBin', item)
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import "@/scss/iviewCssReset/modal";
	@import "@/scss/iviewCssReset/drawer";
	@import "@/scss/iviewCssReset/radio";
	@import "@/scss/iviewCssReset/input";
	@import "@/scss/iviewCssReset/checkbox";
	@import "@/scss/iviewCssReset/tabs";
	@import "@/scss/iviewCssReset/datePicker";
	@import "@/scss/iviewCssReset/select";
	@import "@/scss/iviewCssReset/progress";
	@import "@/scss/iviewCssReset/tree";

	::v-deep {

		// tabs
		.ivu-tabs-bar {
			width: 282px;
			margin: 20px 0;
		}
		
		.ivu-tabs-ink-bar {
		    height: 1px;
		    background-color: #00BAFF;
		}

		.ivu-tabs-nav-scroll {
			background: #4e5357;
			display: flex;
			justify-content: space-around;
		}

		// datepicker
		.ivu-date-picker-rel .ivu-input {
			background-color: #111315;
		}

		.ivu-btn-text {
			background-color: white;
		}
		.ivu-input:focus {
		    border-color: rgba(0,186,255,.5);
		    box-shadow: 0 0 0 2px rgba(0,186,255,.2);
		}
		.ivu-date-picker-focused input {
		    border-color: rgba(0,186,255,.5);
		    box-shadow: 0 0 0 2px rgba(0,186,255,.2);
		}
		.ivu-date-picker-cells-cell-range {
		    color: #00BAFF;
		}
		.ivu-date-picker-rel .ivu-icon {
		    color: #00BAFF;
		    font-size: 16px;
		}
		.ivu-date-picker-cells-cell-selected em, .ivu-date-picker-cells-cell-selected:hover em {
		    background: #00BAFF;
		    color: #fff;
		}
		.ivu-date-picker-cells-focused em {
		    box-shadow: 0 0 0 1px #00BAFF inset;
		}
		.ivu-btn-primary {
		    background-color: #00BAFF;
		    border-color: #00BAFF;
		}
		.ivu-btn:hover {
		    color: #00BAFF;
		    border-color: #00BAFF;
		}
		.ivu-time-picker-cells-cell-selected, .ivu-time-picker-cells-cell-selected:hover {
		    color: #00BAFF;
		}
		.ivu-time-picker-cells-cell:hover {
		    color: #00BAFF;
		}
		.ivu-date-picker-cells-cell-today em:after {
		    background: #00BAFF;
		}
		.ivu-select-selection .ivu-icon {
		    color: #00BAFF;
		    font-size: 16px;
		}
		.ivu-select-visible .ivu-select-selection {
		    box-shadow: 0 0 0 2px rgba(0,186,255,.2);
		}
	}

	.left {
		width: 310px;
		position: absolute;
		left: 0;
		top: 80px;
		z-index: 200;

		.left-title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 42px;
			color: rgba(255, 255, 255, 0.85);
			font-size: 28px;
			margin: 12px 0;
			background: rgba(25, 34, 41, 0.85);
			border-radius: 8px;
			padding: 0 16px;
			box-sizing: border-box;

			p {
				font-weight: bold;
				font-size: 18px;
				color: #fff;
			}

			.left-title-icon {
				display: block;
				font-size: 28px;
				text-align: center;
				cursor: pointer;
			}
		}

		.left-msg {
			.msg-cont {
				width: 362px;
				height: 698px;
				background-image: url(../../../../assets/new/track_698.png);
				background-size: 100% 100%;

				.msg-cont-top {
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					justify-content: space-between;
					padding: 37px 34px 0 20px;

					p {
						width: 160px;
						height: 54px;
						background-image: url(../../../../assets/new/new_light.png);
						background-size: 100% 100%;
						color: rgba(255,255,255,.9);
						font-size: 16px;
						font-weight: bold;
						max-width: 200px;
						word-break: break-all;
						display: flex;
						align-items: center;
						justify-content: center;
					}

					span {
						font-size: 14px;
						font-weight: 500;
						background: rgba(255, 255, 255, 0.2);
						border-radius: 6px;
						padding: 4px 9px;
						color: rgba(255, 255, 255, 0.8);
					}
				}

				.msg-cont-echar {
					display: flex;
					justify-content: space-around;
					padding: 10px 0;
					box-sizing: border-box;
					position: relative;
					min-height: 35px;
				}

				.msg-cont-time {
					font-size: 14px;
					color: rgba(255,255,255,.8);
					font-weight: 500;
					line-height: 20px;
					padding: 24px 36px 0;
				}

				.msg-cont-site {
					font-size: 14px;
					height: 40px;
					color: rgba(255,255,255,.8);
					font-weight: 500;
					line-height: 20px;
					padding: 0 36px;
					margin-bottom: 20px;
					overflow:hidden; 
					text-overflow:ellipsis;
					display:-webkit-box; 
					-webkit-box-orient:vertical;
					-webkit-line-clamp:2; 
					display: flex;
					align-items: center;
				}

				.msg-cont-search {
					min-height: 206px;
					margin-left: 36px;
					.msg-cont-search-line{
						width: 282px;
						height: 1px;
						background-image: url(../../../../assets/new/new_img_line.png);
						background-size: 100% 100%;
					}
					.tabs-cont-time {
						line-height: 1;

						.tabs-cont-time-title {
							display: flex;
							
							li {
								color: rgba(255, 255, 255, 0.85);
								margin-right: 27px;
								cursor: pointer;
							}

							.current {
								color: #00BAFF;
							}
						}

						.time-picker {
							margin: 10px 0 20px;
						  // .ivu-date-picker-cells-cell-range:before {
						  //   background: #25bb96;
						  // }
						}
					}

					.tabs-cont-classes {
						padding-bottom: 16px;
					}

					.msg-cont-search-btns {
						margin-bottom: 20px;
						.msg-cont-search-btns-item {
							width: 88px;
							line-height: 32px;
							color: #ffffff;
							background: rgba(0,186,255,.7);
							border: 0;
							border-radius: 0;
							padding: 0 16px;
							cursor: pointer;
						}
					}
				}

				.msg-cont-total {
					font-size: 14px;
					color: rgba(255,255,255,.8);
					font-weight: 500;
					padding: 20px 36px 0;
					display: flex;
					justify-content: space-between;

					p {
						color: rgba(255, 255, 255, 0.7);

						span {
							color: #fff;
						}
					}
				}

				.mileage-box {
          padding: 6px 36px 0;
					.mileage-box-text {
						display: flex;
						justify-content: space-between;

						p {
							color: rgba(255, 255, 255, 0.65);
						}

						span {
							color: rgba(255, 255, 255, 0.85);
						}
					}
				}
			}
			.msg-pop{
				width: 362px;
				height: 429px;
				background-image: url(../../../../assets/new/new_img_430.png);
				background-size: 100% 100%;
			}
		}

		.left-bottom {
			width: 350px;
			height: 46px;
			border-radius: 6px;
			background-image: url(../../../../assets/new/track_two.png);
			background-size: 100% 100%;
			margin-left: 6px;
		}
		.left-num-zero{
			width: 350px;
			height: 98px;
		}
		.left-num-two{
			width: 350px;
			height: 154px;
			.bin-list-big{
				height: 40px;
			}
		}
		.left-num-four{
			width: 350px;
			height: 200px;
			.bin-list-big{
				height: 80px;
			}
		}
		.left-num-six{
			width: 350px;
			height: 226px;
		}
		
	}

	.bin-box-big {
		height: 46px;
		color: #fff;
		padding: 0px 20px;
		font-size: 16px;
		border-bottom: 1px solid rgba(0,186,255,.5);

		// border-top: 1px solid rgba(255, 255, 255, 0.3);
		.no-clean {
			transition: 0.2s;
		}

		.no-clean-show {
			transform: rotate(90deg);
		}
	}

	.bin-box {
		height: 50px;
		align-items: center;
		padding: 0px 20px;
		display: flex;
		justify-content: space-between;

		p {
			color: rgba(255, 255, 255, 0.7);
		}

		.bin-bit-done {
			color:  rgba(0,186,255,.9);
		}

		.bin-bit-pending {
			color: #faad14;
		}
	}

	.bin-box :hover {
		cursor: pointer;
	}

	.bin-list-big {
		height: 110px;
		border: 1px solid  rgba(0,186,255,.9);
		border-radius: 6px;
		margin: 0 20px 10px;
		padding-bottom: 5px;
		overflow: hidden;
	}

	.bin-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		color:  rgba(0,186,255,.9);
		// background-color: #ccc;
		padding: 10px;
		font-size: 16px;
		max-height: 120px;
		overflow-y: auto;

		// margin: 10px 0;
		div {
			display: flex;
			align-content: center;
			width: 40%;
			cursor: pointer;
			margin-bottom: 10px;

			span {
				margin-left: 5px;
			}
		}
	}

	.bin-list::-webkit-scrollbar-thumb {
		border-radius: 10px;
	}
</style>
